<template>
  <div class="home">
    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">

          <ul class="nav nav-admin">
            <li>
              <router-link to="/account/overview">
                <i class="material-icons">dashboard</i>
                <span>Übersicht</span>
              </router-link>
            </li>
            <li>
              <router-link to="/account/reservations">
                <i class="material-icons">today</i>
                <span>Reservierungen</span>
              </router-link>
            </li>
            <li class="active">
              <router-link to="/account/payment_methods">
                <i class="material-icons">account_balance</i>
                <span>Bezahlmethoden</span>
              </router-link>
            </li>
            <li>
              <router-link to="/account/settings">
                <i class="material-icons">settings</i>
                <span>Einstellungen</span>
              </router-link>
            </li>
          </ul>

        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li>Bezahlmethoden</li>
            </ul>
          </header>

          <div class="admin-body">

            <p>Bitte wählen Sie eine Bezahlmethode, die Sie hinterlegen möchten.</p>

            <div class="payment-options row row-gutter-20">
              <div class="option-wrap col-6">
                <label for="sepa" @click="open_stripe('sepa')">
                  <p><input type="radio" id="sepa" value="sepa" v-model="payment_option"></p>
                  <p><img src="../../../assets/images/methods/sepa.png" width="80" height="40"></p>
                  <h4>SEPA Lastschrift</h4>
                </label>
              </div>
              <div class="option-wrap col-6">
                <label for="card" @click="open_stripe('card')">
                  <p><input type="radio" id="card" value="card" v-model="payment_option"></p>
                  <p><img src="../../../assets/images/methods/card.png" width="80" height="40"></p>
                  <h4>Kreditkarte</h4>
                </label>
              </div>
            </div>

            <div id="payment-sepa" class="payment-sepa payment-method-wrap">

              <h3>Kontodaten eingeben</h3>

              <form action="/charge" method="post" id="sepa-form">
                <div class="row row-gutter-20">
                  <div class="col-12">
                    <div class="form-wrap">
                      <label for="name">Name</label>
                      <input id="sepa-name" name="name" placeholder="Max Mustermann" class="form-input" required>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-wrap">
                      <label for="email">E-Mail Adresse</label>
                      <input id="sepa-email" name="email" type="email" placeholder="max.mustermann@example.com" class="form-input" required>
                    </div>
                  </div>
                </div>

                <div class="form-wrap">
                  <label for="iban-element">IBAN</label>
                  <div id="iban-element"></div>
                </div>
                <div id="bank-name"></div>

                <button id="sepa-button" class="button button-orange button-100 button-small" :data-secret="intent.stripe_client_secret">Kontodaten speichern ›</button>

                <div id="error-message" role="alert"></div>

                <div id="mandate-acceptance" class="disclaimer">
                  Durch die Eingabe Ihrer IBAN und durch das Bestätigen der Bezahlung, authorisieren Sie (A) InTime Autovermietung und Stripe, unseren Bezahldienstleister, die SEPA-Lastschrift bei (B) Ihrer Bank abzubuchen.
                  Sollte die Buchung fehlerhaft durchgeführt worden sein, haben Sie das Recht die Buchung innerhalb von 8 Wochen bei Ihrer Bank und der dort geltenden Bedingungen rückgängig zu machen.
                </div>
              </form>

            </div>

            <div id="payment-card" class="payment-card payment-method-wrap">

              <h3>Kreditkarte hinzufügen</h3>

              <div class="form-wrap">
                <label for="cardholder-name">Name</label>
                <input id="cardholder-name" type="text" class="form-input">
              </div>

              <form id="card-form">

                <div class="form-wrap">
                  <label for="cardholder-name">Kartendetails</label>
                  <div id="card-element"></div>
                </div>

                <button :data-secret="intent.stripe_client_secret" id="card-button" class="button button-orange button-100 button-small">Kreditkarte hinterlegen ›</button>
              </form>

            </div>

          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'home',
  data () {
    return {
      intent: {},
      payment_option: ""
    }
  },
  methods: {
    create_setup_intent() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/payment_setup_intent', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.intent = response.data;
      })
      .catch(error => {
        console.log(error);
      })

    },
    open_stripe(method) {
      if (method == 'card') {
        document.getElementById('payment-sepa').classList.remove('payment-visible');
        document.getElementById('payment-card').classList.add('payment-visible');
      }
      if (method == 'sepa') {
        document.getElementById('payment-card').classList.remove('payment-visible');
        document.getElementById('payment-sepa').classList.add('payment-visible');
      }
    },
    stripe_sepa_action() {

      var elements = this.stripe.elements();

      var style = {
        base: {
          color: '#32325d',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          },
          ':-webkit-autofill': {
            color: '#32325d',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
          ':-webkit-autofill': {
            color: '#fa755a',
          },
        },
      };

      var options = {
        style: style,
        supportedCountries: ['SEPA'],
        placeholderCountry: 'DE',
      };

      var iban = elements.create('iban', options);

      iban.mount('#iban-element');

      var form = document.getElementById('sepa-form');
      var accountholderName = document.getElementById('sepa-name');
      var email = document.getElementById('sepa-email');
      var submitButton = document.getElementById('sepa-button');
      var clientSecret = submitButton.dataset.secret;
      var that = this;

      form.addEventListener('submit', function(event) {
        event.preventDefault();

        that.stripe.confirmSepaDebitSetup(
          clientSecret,
          {
            payment_method: {
              sepa_debit: iban,
              billing_details: {
                name: accountholderName.value,
                email: email.value,
              },
            },
          }
        ).then(function(result) {
          console.log(result);
          if (result.error) {
            console.log(result.error)
          } else {

            axios.post(process.env.VUE_APP_BASE_API+'/v1/user/payment_methods', {
              'method_id': result.setupIntent.payment_method
            }, { headers: { Authorization: that.$store.getters.getToken }})
            .then(response => {
              console.log(response);
              that.$notify({
                group: 'alert',
                type: 'success',
                title: 'Bezahlmethode hinzugefügt',
              });
              that.$router.push('/account/payment_methods');
            })
            .catch(error => {
              this.errors = error.response.data.errors;
            })

          }
        });

      });

    },
    stripe_card_action() {

      var elements = this.stripe.elements();
      var card_element = elements.create('card');

      card_element.mount('#card-element');

      var form = document.getElementById('card-form');
      var cardholderName = document.getElementById('cardholder-name');
      var cardButton = document.getElementById('card-button');
      var clientSecret = cardButton.dataset.secret;
      var that = this;

      form.addEventListener('submit', function(event) {
        event.preventDefault();

        that.stripe.confirmCardSetup(
          clientSecret,
          {
            payment_method: {
              card: card_element,
              billing_details: {
                name: cardholderName.value,
              },
            },
          }
        ).then(function(result) {
          if (result.error) {
            console.log(result.error)
          } else {

            axios.post(process.env.VUE_APP_BASE_API+'/v1/user/payment_methods', {
              'method_id': result.setupIntent.payment_method
            }, { headers: { Authorization: that.$store.getters.getToken }})
            .then(response => {
              console.log(response);
              that.$notify({
                group: 'alert',
                type: 'success',
                title: 'Bezahlmethode hinzugefügt',
              });
              that.$router.push('/account/payment_methods');
            })
            .catch(error => {
              this.errors = error.response.data.errors;
            })

          }
        });
      });

    },
    init_stripe() {
      const Stripe = window.Stripe;
      this.stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);
    }
  },
  mounted () {
    this.create_setup_intent();
    setTimeout(() => {
      this.init_stripe();
      this.stripe_sepa_action();
      this.stripe_card_action();
    }, 1000)
  }
}
</script>
